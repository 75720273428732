/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Link } from "gatsby"
import groupBy from "lodash/groupBy"
import forOwn from "lodash/forOwn"
import max from "lodash/max"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const qnsInitialState = [
	"Constitutional",
	{ group: "Constitutional", text: "Fever", qnId: "1", weight: 2, ans: "" },
	"Hematologic",
	{ group: "Hematologic", text: "Leukopenia", qnId: "2", weight: 3, ans: "" },
	{
		group: "Hematologic",
		text: "Thrombocytopenia",
		qnId: "3",
		weight: 4,
		ans: "",
	},
	{
		group: "Hematologic",
		text: "Autoimmune hemolysis",
		qnId: "4",
		weight: 4,
		ans: "",
	},
	"Neuropsychiatric",
	{
		group: "Neuropsychiatric",
		text: "Delirium",
		qnId: "5",
		weight: 2,
		ans: "",
	},
	{
		group: "Neuropsychiatric",
		text: "Psychosis",
		qnId: "6",
		weight: 3,
		ans: "",
	},
	{ group: "Neuropsychiatric", text: "Seizure", qnId: "7", weight: 5, ans: "" },
	"Mucocutaneous",
	{
		group: "Mucocutaneous",
		text: "Non-scarring alopecia",
		qnId: "8",
		weight: 2,
		ans: "",
	},
	{
		group: "Mucocutaneous",
		text: "Oral ulcers",
		qnId: "9",
		weight: 2,
		ans: "",
	},
	{
		group: "Mucocutaneous",
		text: "Subacute cutaneous OR discoid lupus",
		qnId: "10",
		weight: 4,
		ans: "",
	},
	{
		group: "Mucocutaneous",
		text: "Acute cutaneous lupus",
		qnId: "11",
		weight: 6,
		ans: "",
	},
	"Serosal",
	{
		group: "Serosal",
		text: "Pleural or pericardial effusion",
		qnId: "12",
		weight: 5,
		ans: "",
	},
	{
		group: "Serosal",
		text: "Acute pericarditis",
		qnId: "13",
		weight: 6,
		ans: "",
	},
	"Musculoskeletal",
	{
		group: "Musculoskeletal",
		text: "Joint involvement",
		qnId: "14",
		weight: 6,
		ans: "",
	},
	"Renal",
	{
		group: "Renal",
		text: "Proteinuria >0.5g/24 hours",
		qnId: "15",
		weight: 4,
		ans: "",
	},
	{
		group: "Renal",
		text: "Renal biopsy class II or V lupus nephritis",
		qnId: "16",
		weight: 8,
		ans: "",
	},
	{
		group: "Renal",
		text: "Renal biopsy class III or IV lupus nephritis",
		qnId: "17",
		weight: 10,
		ans: "",
	},
	"Antiphospholipid antibodies",
	{
		group: "Antiphospholipid",
		text:
			"Anti-cardiolipin antibodies OR Anti-β2GP1 antibodies OR Lupus anticoagulant",
		qnId: "18",
		weight: 2,
		ans: "",
	},
	"Complement proteins",
	{
		group: "Complement",
		text: "Low C3 OR low C4",
		qnId: "19",
		weight: 3,
		ans: "",
	},
	{
		group: "Complement",
		text: "Low C3 AND low C4",
		qnId: "20",
		weight: 4,
		ans: "",
	},
	"SLE-specific antibodies",
	{
		group: "antibodies",
		text: "Anti-dsDNA antibody OR Anti-Smith antibody",
		qnId: "21",
		weight: 6,
		ans: "",
	},
]

const radio =
	"focus:outline-none rounded text-purple-800 py-2 text-center w-32 mr-4 hover:bg-purple-200 focus:bg-purple-200"

const active = `${radio} shadow-inner bg-purple-200 border-purple-300 font-semibold`

const inactive = `${radio} shadow-md bg-purple-100 border-purple-100`

const Qn = ({ text, qnId, ans, onChange }) => {
	return (
		<div className="mx-6 mb-12">
			<p>{text}</p>
			<button
				type="button"
				onClick={() => onChange(qnId, "yes")}
				className={ans === "yes" ? active : inactive}
			>
				Yes
			</button>
			<button
				type="button"
				onClick={() => onChange(qnId, "no")}
				className={ans === "no" ? active : inactive}
			>
				No
			</button>
		</div>
	)
}

// Message to display if entry criteria is met
const Message = ({ score, clinicalDomainPresent }) => {
	if (!clinicalDomainPresent) {
		return (
			<>
				<h3 className="text-purple-600 tracking-wide text-xl font-bold">
					Negative
				</h3>
				<p>
					In the absence of any clinical criteria being met, you do not have
					lupus under the criteria used.
				</p>
			</>
		)
	}
	if (score >= 10) {
		return (
			<>
				<h3 className="text-purple-600 tracking-wide text-xl font-bold">
					Positive
				</h3>
				<p>
					Based on the answers you have given (a positive ANA test, the presence
					of one or more clinical criteria and an overall score of{" "}
					<span className="font-semibold text-purple-700">{score}</span>), you
					DO meet the criteria for a positive lupus diagnosis.
				</p>
			</>
		)
	}
	return (
		<>
			<h3 className="text-purple-600 tracking-wide text-xl font-bold">
				Negative
			</h3>
			<p>
				Based on the answers you have given (a positive ANA test, the presence
				of one or more clinical criteria and an overall score of{" "}
				<span className="font-semibold text-purple-700">{score}</span>), you DO
				NOT meet the criteria for a positive lupus diagnosis.
			</p>
		</>
	)
}

const Quiz = () => {
	const [stage, setStage] = useState("entry") // entry, quiz, complete
	const [qns, setQns] = useState(qnsInitialState)
	const [entry, setEntry] = useState("")
	const grouped = groupBy(qns, "group")
	let score = 0
	let clinicalDomainPresent = false
	forOwn(grouped, (group, groupName) => {
		const weights = group.map(({ ans, weight }) => (ans === "yes" ? weight : 0))
		const maxWeight = max(weights)
		score += maxWeight
		const clinicalGroups = [
			"Constitutional",
			"Hematologic",
			"Neuropsychiatric",
			"Mucocutaneous",
			"Serosal",
			"Musculoskeletal",
			"Renal",
		]
		if (clinicalGroups.includes(groupName) && maxWeight > 0) {
			clinicalDomainPresent = true
		}
	})

	return (
		<Layout>
			<SEO title="Do I have lupus?" />
			<h1>Do I have lupus?</h1>
			<p>
				The quiz below is based on{" "}
				<Link
					className="link"
					to="/lupus-diagnostic-guidelines/newest-criteria-for-lupus-diagnosis"
				>
					these guidelines
				</Link>
				. You can use it to determine the likelihood of you having lupus.
			</p>
			<div className="text-lg shadow-md px-8 pb-8 pt-4">
				<h2 className="text-center">Quiz</h2>
				{stage === "entry" && (
					<div>
						<h3 className="text-purple-600 tracking-wide text-xl font-bold">
							Entry criterion
						</h3>
						<Qn
							text="Have you had an ANA (antinuclear antibodies) test done and tested positive for it?"
							qnId="entry"
							ans={entry}
							onChange={(_qnId, ans) => {
								setEntry(ans)
								setStage(ans === "yes" ? "quiz" : "complete")
							}}
						/>
					</div>
				)}

				{stage === "quiz" && (
					<>
						<h3 className="text-purple-600 tracking-wide text-xl font-bold">
							Additive criteria
						</h3>
						<ul className="text-base">
							<li>
								Do not count any of the below if there is a more likely
								explanation than lupus.
							</li>
							<li>
								Occurrence of a criterion on at least one occasion is sufficient
							</li>
						</ul>
						{qns.map(qn =>
							qn.qnId ? (
								<Qn
									key={qn.qnId}
									{...qn}
									onChange={(id, ans) => {
										setQns(
											qns.map(q => {
												const { qnId } = q
												if (id === qnId) {
													return { ...q, ans }
												}
												return q
											})
										)
									}}
								/>
							) : (
								<p key={qn} className="text-xl font-semibold tracking-normal">
									{qn}
								</p>
							)
						)}
						<button
							type="button"
							className="btn"
							onClick={() => setStage("complete")}
						>
							Check
						</button>
					</>
				)}
				{stage === "complete" && (
					<>
						{entry !== "yes" ? (
							<p>
								Without having completed an ANA test and testing positive for
								it, you do not classify as having lupus under the criteria used.
							</p>
						) : (
							<Message
								score={score}
								clinicalDomainPresent={clinicalDomainPresent}
							/>
						)}
						<button
							type="button"
							className="btn"
							onClick={() => setStage("entry")}
						>
							Back to questions
						</button>
					</>
				)}
			</div>
		</Layout>
	)
}

export default Quiz
